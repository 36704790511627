// Header.js
import React, { useState, useEffect, useRef } from 'react';
import UserMenu from './UserMenu';
import ManageAccountModal from './ManageAccountModal';
import ManagePersonasModal from './ManagePersonasModal';
import './Header.css';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isManageAccountModalOpen, setIsManageAccountModalOpen] = useState(false);
  const [isManagePersonasModalOpen, setIsManagePersonasModalOpen] = useState(false);
  const menuRef = useRef(null);
  const iconRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isMenuOpen && 
          menuRef.current && 
          !menuRef.current.contains(event.target) &&
          !iconRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isMenuOpen]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const openManageAccountModal = () => {
    setIsManageAccountModalOpen(true);
    closeMenu();
  };

  const openManagePersonasModal = () => {
    setIsManagePersonasModalOpen(true);
    closeMenu();
  };

  return (
    <header className="jibbit-header">
      <div className="logo"></div>
      <div className="user-icon-container">
        <div className="user-icon" onClick={toggleMenu} ref={iconRef}>👤</div>
        {isMenuOpen && (
          <div ref={menuRef}>
            <UserMenu 
              onManageAccount={openManageAccountModal} 
              onManagePersonas={openManagePersonasModal}
            />
          </div>
        )}
        {isManageAccountModalOpen && (
          <ManageAccountModal onClose={() => setIsManageAccountModalOpen(false)} />
        )}
        {isManagePersonasModalOpen && (
          <ManagePersonasModal onClose={() => setIsManagePersonasModalOpen(false)} />
        )}
      </div>
    </header>
  );
};

export default Header;