// src/components/ProtectedRoute.js

import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { getAccessToken } from '../utils/auth';
import { useUser } from '../UserContext';

const ProtectedRoute = ({ children }) => {
  const token = getAccessToken();
  const { accountId, userId } = useUser();
  const location = useLocation();

  if (!token) {
    return <Navigate to="/login" />;
  }

  if (accountId && userId && location.pathname === '/') {
    return <Navigate to={`/accounts/${accountId}/users/${userId}`} />;
  }

  return children;
};

export default ProtectedRoute;