// src/pages/ConfirmSignUp.js
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import api from '../utils/api';
import './ConfirmSignUp.css';

const API_URL = 'https://5h73bny644.execute-api.us-east-1.amazonaws.com/dev';

const ConfirmSignUp = () => {
  const [code, setCode] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const emailFromState = location.state?.email;
    if (emailFromState) {
      setEmail(emailFromState);
    } else {
      setError('Email not provided. Please go back and try again.');
    }
  }, [location.state]);

  const handleConfirmation = async (event) => {
    event.preventDefault();
    setError(null);
    setMessage(null);
    if (!email) {
      setError('Email is required. Please go back and try again.');
      return;
    }
    try {
      console.log('Sending confirmation request:', { username: email, confirmation_code: code });
      const response = await api.post(`${API_URL}/confirm-user-signup`, {
        username: email,
        confirmation_code: code
      });

      console.log('Confirmation response:', response.data);
      const data = response.data;

      if (data.status === 'CONFIRMED') {
        setMessage('Email confirmed successfully! You can now log in.');
        setTimeout(() => navigate('/login'), 3000);
      } else {
        console.log('Unexpected response:', data);
        setError(`An unexpected response was received: ${JSON.stringify(data)}`);
      }
    } catch (error) {
      console.error('Error confirming signup:', error);
      if (error.response) {
        console.error('Error response:', error.response.data);
        setError(`Failed to confirm signup: ${error.response.data.message || error.response.statusText}`);
      } else if (error.request) {
        console.error('No response received:', error.request);
        setError('No response received from the server. Please try again.');
      } else {
        console.error('Error setting up request:', error.message);
        setError(`Error: ${error.message}`);
      }
    }
  };

  const handleResendCode = async () => {
    setError(null);
    setMessage(null);
    try {
      console.log('Sending resend code request:', { username: email });
      const response = await api.post(`${API_URL}/resend-confirmation-code`, {
        username: email
      });

      console.log('Resend code response:', response.data);
      setMessage('A new confirmation code has been sent to your email.');
    } catch (error) {
      console.error('Error resending code:', error);
      if (error.response) {
        console.error('Error response:', error.response.data);
        setError(`Failed to resend code: ${error.response.data.message || error.response.statusText}`);
      } else if (error.request) {
        console.error('No response received:', error.request);
        setError('No response received from the server. Please try again.');
      } else {
        console.error('Error setting up request:', error.message);
        setError(`Error: ${error.message}`);
      }
    }
  };

  return (
    <div className="auth-form">
      <h1>Confirm Sign Up</h1>
      {error && <p className="error-message">{error}</p>}
      {message && <p className="success-message">{message}</p>}
      {email ? (
        <form onSubmit={handleConfirmation}>
          <div className="form-group">
            <label>Email:</label>
            <input
              type="email"
              value={email}
              readOnly
            />
          </div>
          <div className="form-group">
            <label>Confirmation Code:</label>
            <input
              type="text"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              required
            />
          </div>
          <button type="submit">Confirm</button>
          <button type="button" onClick={handleResendCode} className="secondary-button">
            Resend Code
          </button>
          <p>Go back to <Link to="/login">Login</Link></p>
        </form>
      ) : (
        <p>
          <Link to="/signup">Go back to sign up</Link> or <Link to="/login">try logging in</Link>
        </p>
      )}
    </div>
  );
};

export default ConfirmSignUp;