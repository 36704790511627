// src/UserContext.js

import React, { createContext, useContext, useState } from 'react';

// Create a context to hold accountId and userId
const UserContext = createContext();

// Custom hook to consume the context
export const useUser = () => useContext(UserContext);

// Provider component to wrap your App and provide context
export const UserProvider = ({ children }) => {
  const [accountId, setAccountId] = useState(null);
  const [userId, setUserId] = useState(null);
  const [chatId, setChatId] = useState(null);

  return (
    <UserContext.Provider value={{ accountId, setAccountId, userId, setUserId, chatId, setChatId }}>
      {children}
    </UserContext.Provider>
  );
};