import React, { useState, useMemo, useCallback } from 'react';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { materialOceanic as style } from 'react-syntax-highlighter/dist/esm/styles/prism';
import './ChatMessage.css';

const ChatMessage = React.memo(({ sender, time, text, isCurrentUser, status }) => { // Added status
  const [copySuccess, setCopySuccess] = useState('');

  // Format date to match the user's local time zone
  const formatDate = useCallback((timestamp) => {
    // Parse timestamp using Date.UTC to ensure it's interpreted as UTC
    const date = new Date(Date.UTC(
      parseInt(timestamp.substr(0, 4)), // year
      parseInt(timestamp.substr(5, 2)) - 1, // month is 0-based
      parseInt(timestamp.substr(8, 2)), // day
      parseInt(timestamp.substr(11, 2)), // hours
      parseInt(timestamp.substr(14, 2)), // minutes
      parseInt(timestamp.substr(17, 2)), // seconds
    ));

    if (isNaN(date.getTime())) {
      console.error('Invalid timestamp:', timestamp);
      return 'Invalid Date';
    }

    // Convert and format the date and time according to the user's local time zone
    const formattedDate = date.toLocaleDateString(undefined, {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
    const formattedTime = date.toLocaleTimeString(undefined, {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    });

    return `${formattedDate} ${formattedTime}`;
  }, []);

  const handleCopy = useCallback((content) => {
    navigator.clipboard.writeText(content).then(() => {
      setCopySuccess('Copied!');
      setTimeout(() => setCopySuccess(''), 5000);
    });
  }, []);

  const components = useMemo(() => ({
    code({ node, inline, className, filename, children, ...props }) {
      const match = /language-(\w+)/.exec(className || '');

      return !inline && match ? (
        <pre className="code-block">
          <div className="code-header">
            {filename && <span className="filename">{filename}</span>}
            <button
              className="copy-button"
              onClick={() => handleCopy(String(children).replace(/\n$/, ''))}
            >
              <i className="fas fa-clipboard"></i> {/* FontAwesome Icon */}
            </button>
          </div>
          <div className="code-content">
            <SyntaxHighlighter
              style={style}
              language={match[1]}
              children={String(children).replace(/\n$/, '')}
              {...props}
            />
          </div>
          {copySuccess && <span className="copy-success">{copySuccess}</span>}
        </pre>
      ) : (
        <code className={className} {...props}>
          {children}
        </code>
      );
    },
  }), [handleCopy, copySuccess]);

  return (
    <div className={`chat-message ${isCurrentUser ? 'current-user' : ''}`}>
      <div className="chat-message-info">
        <span className="chat-message-sender">{sender}</span>
        <span className="chat-message-time">{formatDate(time)}</span>
      </div>
      <div className="chat-message-text">
        <ReactMarkdown components={components}>{text}</ReactMarkdown>
      </div>
      {status === 'pending' && <div className="chat-message-status">Sending...</div>}
      {status === 'error' && <div className="chat-message-status error">Failed to send</div>}
    </div>
  );
});

export default ChatMessage;