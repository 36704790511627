// src/utils/api.js

import axios from 'axios';
import { getAccessToken, clearTokens } from './auth';

const api = axios.create({
  baseURL: 'https://5h73bny644.execute-api.us-east-1.amazonaws.com/dev'
});

api.interceptors.request.use(
  config => {
    const token = getAccessToken();
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  error => Promise.reject(error)
);

api.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      clearTokens();
      // We can't use useNavigate here, so we'll use window.location
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

export default api;