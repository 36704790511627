import React, { useState, useEffect, useCallback } from 'react';
import { useUser } from '../UserContext';
import api from '../utils/api'; // Update the import path if necessary
import './ManageAccountModal.css';

const API_BASE_URL = "https://5h73bny644.execute-api.us-east-1.amazonaws.com/dev";

const ManageAccountModal = ({ onClose }) => {
  const { accountId } = useUser();
  const [companyName, setCompanyName] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchAccountDetails = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await api.get(`${API_BASE_URL}/accounts/${accountId}`);
      setCompanyName(response.data.company_name);
      setError(null);
    } catch (err) {
      setError('Error fetching account details: ' + (err.response?.data?.message || err.message));
    } finally {
      setIsLoading(false);
    }
  }, [accountId]);

  useEffect(() => {
    fetchAccountDetails();
  }, [fetchAccountDetails]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await api.put(`${API_BASE_URL}/accounts/${accountId}`, { company_name: companyName });
      setError(null);
      onClose();
    } catch (err) {
      setError('Error updating account: ' + (err.response?.data?.message || err.message));
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) return <div className="modal">Loading...</div>;

  return (
    <div className="modal-overlay">
      <div className="modal">
        <h2>Manage Account</h2>
        {error && <div className="error-message">{error}</div>}
        <form onSubmit={handleSubmit}>
          <label>
            Company Name:
            <input
              type="text"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
            />
          </label>
          <div className="future-section">
            <h3>Manage Users</h3>
            <p>This feature will be available soon.</p>
          </div>
          <div className="modal-actions">
            <button type="submit" disabled={isLoading}>
              {isLoading ? 'Saving...' : 'Save Changes'}
            </button>
            <button type="button" onClick={onClose}>Cancel</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ManageAccountModal;