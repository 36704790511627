import React, { useState, useEffect } from 'react';
import { useUser } from '../UserContext';
import { Link } from 'react-router-dom';
import api from '../utils/api';
import CreateChatModal from './CreateChatModal';
import './Sidebar.css';

const API_BASE_URL = "https://5h73bny644.execute-api.us-east-1.amazonaws.com/dev";

const Sidebar = () => {
  const { accountId, userId, chatId } = useUser();
  const [chats, setChats] = useState([]);
  const [isCreateChatModalOpen, setIsCreateChatModalOpen] = useState(false);

  useEffect(() => {
    const fetchChats = async () => {
      try {
        const response = await api.get(`${API_BASE_URL}/accounts/${accountId}/users/${userId}/chats`);
        setChats(response.data);
      } catch (error) {
        console.error('Failed to fetch chats', error);
      }
    };

    if (accountId && userId) {
      fetchChats();
    }
  }, [accountId, userId]);

  const handleChatCreated = (newChat) => {
    setChats([...chats, newChat]);
  };

  const handleOpenModal = () => {
    setIsCreateChatModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsCreateChatModalOpen(false);
  };

  return (
    <>
      <aside className="sidebar">
        <div className="chat-list-header">
          <div className="chat-list-header-label">
            <div className="chat-list-label-text">Chats:&nbsp;&nbsp;</div>
            <button className="add-chat-button" onClick={handleOpenModal}>+</button>
          </div>
        </div>
        <div className="chat-list">
          {chats.length > 0 ? (
            chats.map(chat => (
              <Link
                key={chat.chat_id}
                to={`/accounts/${accountId}/users/${userId}/chats/${chat.chat_id}`}
                className={`chat-item ${chat.chat_id === chatId ? 'active' : ''}`}
              >
                {chat.chat_name || 'Unnamed Chat'}
              </Link>
            ))
          ) : (
            <p>No chats available</p>
          )}
        </div>
      </aside>
      {isCreateChatModalOpen && (
        <CreateChatModal
          onClose={handleCloseModal}
          onChatCreated={handleChatCreated}
        />
      )}
    </>
  );
};

export default Sidebar;