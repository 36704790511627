// src/services/WebSocketProvider.js
import React, { createContext, useContext, useEffect, useRef, useState, useCallback } from 'react';

const WebSocketContext = createContext(null);

export const WebSocketProvider = ({ children }) => {
  const [connection, setConnection] = useState(null);
  const websocketRef = useRef(null);
  const [messageQueue, setMessageQueue] = useState([]);
  const reconnectionAttempts = useRef(0);

  const initializeWebSocket = (accountId, userId) => {
    if (websocketRef.current) {
      if (websocketRef.current.readyState === WebSocket.OPEN || websocketRef.current.readyState === WebSocket.CONNECTING) {
        return;
      }
    }
    connectWebSocket(accountId, userId);
  };

  const closeWebSocket = () => {
    if (websocketRef.current) {
      websocketRef.current.close();
      websocketRef.current = null;
      setConnection(null);
    }
  };

  const connectWebSocket = useCallback((accountId, userId) => {
    if (!accountId || !userId) return;

    const ws = new WebSocket(`wss://f4ws1u4qh4.execute-api.us-east-1.amazonaws.com/dev?account_id=${accountId}&user_id=${userId}`);
    
    ws.onopen = () => {
      console.log('WebSocket connected');
      reconnectionAttempts.current = 0;
      setConnection(ws);
    };

    ws.onmessage = (event) => {
      const message = JSON.parse(event.data);
      console.log('WebSocket message received:', message);
      setMessageQueue(prevQueue => [...prevQueue, message]);
    };

    ws.onclose = () => {
      console.log('WebSocket closed, attempting to reconnect...');
      if (reconnectionAttempts.current < 5) {
        setTimeout(() => {
          reconnectionAttempts.current += 1;
          connectWebSocket(accountId, userId);
        }, Math.min(30000, 1000 * 2 ** reconnectionAttempts.current));
      } else {
        console.error('Max reconnection attempts reached.');
      }
    };

    ws.onerror = (error) => {
      console.error('WebSocket error:', error);
      ws.close();
    };

    websocketRef.current = ws;
  }, []);

  useEffect(() => {
    return () => {
      closeWebSocket();
    };
  }, [connectWebSocket]);

  return (
    <WebSocketContext.Provider value={{ connection, initializeWebSocket, closeWebSocket, messageQueue, setMessageQueue }}>
      {children}
    </WebSocketContext.Provider>
  );
};

export const useWebSocket = () => useContext(WebSocketContext);