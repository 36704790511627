import React from 'react';
import './ParticipantBubble.css';

const ParticipantBubble = ({ name, onRemove, isCurrentUser }) => (
  <div className={`participant-bubble ${isCurrentUser ? 'current-user' : ''}`}>
    {name}
    {!isCurrentUser && (
      <button className="remove-button" onClick={onRemove}>x</button>
    )}
  </div>
);

export default ParticipantBubble;