// src/components/ChatWindow.js
import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import { useUser } from "../UserContext";
import { useWebSocket } from "../services/WebSocketProvider";
import ChatMessage from "./ChatMessage";
import ParticipantBubble from "./ParticipantBubble";
import AutocompleteInput from "./AutocompleteInput";
import api from '../utils/api';
import "./ChatWindow.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { getAccessToken } from "../utils/auth";

const API_BASE_URL = "https://5h73bny644.execute-api.us-east-1.amazonaws.com/dev";

const ChatWindow = () => {
  const { accountId, userId, chatId } = useUser();
  const { messageQueue, setMessageQueue } = useWebSocket();  
  const [participants, setParticipants] = useState([]);
  const [allUsers, setAllUsers] = useState(new Map());
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState("");
  const messagesEndRef = useRef(null);
  const textareaRef = useRef(null);

  const getAuthConfig = useCallback(() => {
    const token = getAccessToken();
    return {
      headers: { Authorization: `Bearer ${token}` }
    };
  }, []);

  useEffect(() => {
    const fetchUsersAndPersonas = async () => {
      if (!accountId) return;

      try {
        console.log("Fetching users and personas for account:", accountId);
        const authConfig = getAuthConfig();
        console.log("Auth config:", authConfig);

        const usersResponse = await api.get(
          `${API_BASE_URL}/accounts/${accountId}/users`,
          authConfig
        );
        console.log("Users response:", usersResponse.data);

        const personasResponse = await api.get(
          `${API_BASE_URL}/accounts/${accountId}/personas`,
          authConfig
        );
        console.log("Personas response:", personasResponse.data);

        const usersMap = new Map(
          usersResponse.data.map((user) => [
            user.user_id,
            {
              id: user.user_id,
              name: `${user.first_name} ${user.last_name}`,
              type: "user",
            },
          ])
        );

        const personasMap = new Map(
          personasResponse.data.map((persona) => [
            persona.persona_id,
            { id: persona.persona_id, name: persona.name, type: "persona" },
          ])
        );

        const combinedMap = new Map([...usersMap, ...personasMap]);
        setAllUsers(combinedMap);
      } catch (error) {
        console.error("Failed to fetch users and personas", error);
        console.log("Error response:", error.response);
      }
    };

    fetchUsersAndPersonas();
  }, [accountId, getAuthConfig]);

  const fetchParticipantsAndMessages = useCallback(async () => {
    if (!chatId || !accountId || allUsers.size === 0) return;

    try {
      console.log("Fetching participants and messages for chat:", chatId);
      const authConfig = getAuthConfig();
      console.log("Auth config:", authConfig);

      const response = await api.get(
        `${API_BASE_URL}/accounts/${accountId}/users/${userId}/chats/${chatId}/messages`,
        authConfig
      );
      console.log("Chat data response:", response.data);

      const chatData = response.data;

      const participantsWithNames = chatData.chat.Participants.map(
        (participant) => {
          const participantData = allUsers.get(participant.participant_id);
          return {
            id: participant.participant_id,
            name: participantData ? participantData.name : "Unknown",
            type: participant.type,
          };
        }
      );

      setParticipants(participantsWithNames);

      const messagesWithTypes = chatData.messages.map((message) => ({
        ...message,
        participantType: chatData.chat.Participants.find(
          (p) => p.participant_id === message.SenderID
        ).type,
      }));

      const updatedMessages = messagesWithTypes.map((message) => {
        const nameObj = allUsers.get(message.SenderID);
        const senderName = nameObj ? nameObj.name : "Unknown";
        return {
          ...message,
          senderName,
          isCurrentUser: message.SenderID === userId,
        };
      });

      setMessages(updatedMessages);
    } catch (error) {
      console.error("Failed to fetch chat", error);
      console.log("Error response:", error.response);
    }
  }, [accountId, userId, chatId, allUsers, getAuthConfig]);

  useEffect(() => {
    setMessages([]);
    setParticipants([]);
    fetchParticipantsAndMessages();
  }, [fetchParticipantsAndMessages, chatId, allUsers]);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  useEffect(() => {
    console.log("New messageQueue received:", messageQueue);
    if (messageQueue.length > 0) {
      console.log("Processing WebSocket message:", messageQueue[0]);
      fetchParticipantsAndMessages();
      setMessageQueue((prevQueue) => prevQueue.slice(1));
    }
  }, [messageQueue, setMessageQueue, fetchParticipantsAndMessages]);

  const handleInputChange = (e) => {
    setMessage(e.target.value);
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  const handleSendMessage = async () => {
    if (!userId || !chatId || !accountId) return;

    const newMessage = {
      MessageID: Date.now().toString(),
      SenderID: userId,
      Timestamp: new Date().toISOString(),
      MessageText: message,
      participantType: "user",
      isCurrentUser: true,
      senderName: "You",
      status: "pending",
    };

    setMessages((prevMessages) => [...prevMessages, newMessage]);
    setMessage("");
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "60px";
    }

    sendMessageToBackend(
      accountId,
      userId,
      chatId,
      message,
      newMessage.MessageID,
      (error, success) => {
        if (error) {
          console.error("Failed to send message", error);
          setMessages((messages) =>
            messages.map((msg) =>
              msg.MessageID === newMessage.MessageID ? { ...msg, status: "error" } : msg
            )
          );
        } else {
          setMessages((messages) =>
            messages.map((msg) =>
              msg.MessageID === newMessage.MessageID ? { ...msg, status: "success" } : msg
            )
          );
        }
      }
    );
  };

  const sendMessageToBackend = async (accountId, userId, chatId, message, messageId, callback) => {
    try {
      console.log("Sending message to backend");
      const authConfig = getAuthConfig();
      console.log("Auth config:", authConfig);

      const response = await api.post(
        `${API_BASE_URL}/accounts/${accountId}/users/${userId}/chats/${chatId}/messages`,
        { message_text: message, message_file_url: "" },
        authConfig
      );
      console.log("Send message response:", response.data);
      callback(null, true);
    } catch (error) {
      console.error("Failed to send message", error);
      console.log("Error response:", error.response);
      callback(error, false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      if (e.shiftKey) {
        return;
      } else {
        e.preventDefault();
        handleSendMessage();
      }
    }
  };

  const handleAddParticipant = (name) => {
    if (!participants.some((participant) => participant.name === name)) {
      setParticipants([...participants, { name }]);
    }
  };

  const handleRemoveParticipant = useCallback((name) => {
    setParticipants(
      participants.filter((participant) => participant.name !== name)
    );
  }, [participants]);

  const memoizedParticipants = useMemo(
    () =>
      participants.map((participant) => (
        <ParticipantBubble
          key={participant.id}
          name={participant.name}
          onRemove={() => handleRemoveParticipant(participant.name)}
        />
      )),
    [participants, handleRemoveParticipant]
  );

  const memoizedMessages = useMemo(
    () =>
      messages.map((message) => (
        <ChatMessage
          key={message.MessageID}
          sender={message.senderName}
          time={message.Timestamp}
          text={message.MessageText}
          isCurrentUser={message.isCurrentUser}
          status={message.status}
        />
      )),
    [messages]
  );

  return (
    <div className="chat-window">
      <div className="chat-header">
        <div className="chat-participants">
          <div className="chat-participants-label">Participants:&nbsp;&nbsp;</div>
          {memoizedParticipants}
          <AutocompleteInput
            suggestions={[...allUsers.values()].map((user) => user.name)}
            onAddParticipant={handleAddParticipant}
          />
        </div>
      </div>
      <div className="chat-messages">
        {memoizedMessages}
        <div ref={messagesEndRef} />
      </div>
      <div className="chat-input">
        <textarea
          className="message-textarea"
          value={message}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          ref={textareaRef}
          placeholder="Type your message here..."
        />
        <button className="send-button" onClick={handleSendMessage}>
          <FontAwesomeIcon icon={faPaperPlane} />
        </button>
      </div>
    </div>
  );
};

export default ChatWindow;