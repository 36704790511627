import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useUser } from './UserContext';
import { useWebSocket } from './services/WebSocketProvider';
import { getAccessToken } from './utils/auth';

const SetUserContext = ({ children }) => {
  const { accountId: paramAccountId, userId: paramUserId, chatId: paramChatId } = useParams();
  const { accountId, setAccountId, userId, setUserId, chatId, setChatId } = useUser();
  const { initializeWebSocket, closeWebSocket } = useWebSocket();
  const navigate = useNavigate();

  useEffect(() => {
    const checkTokenAndRedirect = async () => {
      const token = getAccessToken();
      if (!token) {
        navigate('/login');
        return;
      }

      const shouldInitWebSocket = paramAccountId && paramUserId && 
        (paramAccountId !== accountId || paramUserId !== userId);

      if (shouldInitWebSocket) {
        closeWebSocket(); // Close existing connection before initializing a new one
        initializeWebSocket(paramAccountId, paramUserId);
        setAccountId(paramAccountId);
        setUserId(paramUserId);
      }

      // Always update chatId independently
      if (paramChatId !== chatId) {
        setChatId(paramChatId);
      }

      // If we're at the root path and have account and user IDs, redirect
      if (window.location.pathname === '/' && accountId && userId) {
        navigate(`/accounts/${accountId}/users/${userId}`);
      }
    };

    checkTokenAndRedirect();
  }, [paramAccountId, paramUserId, paramChatId, accountId, userId, chatId, 
      setAccountId, setUserId, setChatId, initializeWebSocket, closeWebSocket, navigate]);

  return children;
};

export default SetUserContext;