import React, { useState, useEffect, useRef } from 'react';
import './AutocompleteInput.css';

const AutocompleteInput = ({ suggestions, onAddParticipant }) => {
  const [inputValue, setInputValue] = useState('');
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputValue) {
      const filtered = suggestions.filter(suggestion =>
        suggestion && typeof suggestion === 'string' &&
        suggestion.toLowerCase().includes(inputValue.toLowerCase())
      );
      setFilteredSuggestions(filtered);
      setShowSuggestions(true);
    } else {
      setShowSuggestions(false);
    }
  }, [inputValue, suggestions]);

  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSelect = (suggestion) => {
    onAddParticipant(suggestion);
    setInputValue('');
    setShowSuggestions(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && inputValue) {
      handleSelect(inputValue);
    }
  };

  return (
    <div className="autocomplete-input">
      <input
        ref={inputRef}
        type="text"
        className="autocomplete-input-field"
        value={inputValue}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        placeholder="Type to search participants"
      />
      {showSuggestions && (
        <ul className="autocomplete-suggestions">
          {filteredSuggestions.map((suggestion, index) => (
            <li key={index} onClick={() => handleSelect(suggestion)}>
              {suggestion}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default AutocompleteInput;