// Login.js
import React, { useState, useCallback } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { saveTokens } from '../utils/auth';
import { useUser } from '../UserContext';
import axios from 'axios';
import './Login.css';

const API_BASE_URL = "https://5h73bny644.execute-api.us-east-1.amazonaws.com/dev";

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { setAccountId, setUserId } = useUser();

  const handleSignIn = useCallback(async (event) => {
    event.preventDefault();
    setError(null);
    try {
      const response = await axios.post(`${API_BASE_URL}/login`, { username: email, password });
      const data = response.data;

      if (data.status === 'SUCCESS') {
        saveTokens(data.token, data.refresh_token, data.expires_in);
        setAccountId(data.account_ids[0]);
        setUserId(data.user_id);
        navigate(`/accounts/${data.account_ids[0]}/users/${data.user_id}`);
      } else if (data.status === 'CHALLENGE') {
        console.log('Additional challenge required:', data.challenge_name);
        setError('Additional authentication step required. Please contact support.');
      } else {
        setError('Unexpected response from server. Please try again.');
      }
    } catch (error) {
      console.error('Error signing in:', error);
      if (error.response) {
        const errorMessage = error.response.data.message;
        if (errorMessage.includes('User is not confirmed')) {
          navigate('/confirm-signup', { state: { email } });
        } else if (errorMessage.includes('Incorrect username or password')) {
          setError('Invalid email or password. Please try again.');
        } else if (errorMessage.includes('User does not exist')) {
          setError('No account found with this email. Please sign up.');
        } else if (errorMessage.includes('Password reset required')) {
          setError('Password reset required. Please check your email for instructions.');
        } else {
          setError(errorMessage || 'An error occurred. Please try again.');
        }
      } else {
        setError('Failed to connect to the server. Please try again later.');
      }
    }
  }, [email, password, navigate, setAccountId, setUserId]);

  return (
    <div className="auth-form">
      <h1>Login</h1>
      {error && <p className="error-message">{error}</p>}
      <form onSubmit={handleSignIn}>
        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input
            id="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password:</label>
          <input
            id="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit">Login</button>
      </form>
      <p>Don't have an account? <Link to="/signup">Sign up here</Link></p>
    </div>
  );
};

export default Login;