import React, { useState, useEffect, useCallback } from 'react';
import { useUser } from '../UserContext';
import api from '../utils/api';
import './ManagePersonasModal.css';

const API_BASE_URL = "https://5h73bny644.execute-api.us-east-1.amazonaws.com/dev";

const ManagePersonasModal = ({ onClose }) => {
  const { accountId } = useUser();
  const [personas, setPersonas] = useState([]);
  const [selectedPersona, setSelectedPersona] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchPersonas = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await api.get(`${API_BASE_URL}/accounts/${accountId}/personas`);
      setPersonas(response.data);
      setError(null);
    } catch (err) {
      setError('Error fetching personas: ' + (err.response?.data?.message || err.message));
    } finally {
      setIsLoading(false);
    }
  }, [accountId]);

  useEffect(() => {
    fetchPersonas();
  }, [fetchPersonas]);

  const handlePersonaSelect = (persona) => {
    setSelectedPersona({ ...persona });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedPersona(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      if (selectedPersona.persona_id) {
        await api.put(`${API_BASE_URL}/accounts/${accountId}/personas/${selectedPersona.persona_id}`, selectedPersona);
      } else {
        await api.post(`${API_BASE_URL}/accounts/${accountId}/personas`, selectedPersona);
      }
      await fetchPersonas();
      setSelectedPersona(null);
    } catch (err) {
      setError('Error saving persona: ' + (err.response?.data?.message || err.message));
    } finally {
      setIsLoading(false);
    }
  };

  const handleNewPersona = () => {
    setSelectedPersona({
      name: '',
      title: '',
      description: '',
      model: 'claude-3-5-sonnet-latest',
      context: ''
    });
  };

  if (isLoading) return <div className="modal">Loading...</div>;

  return (
    <div className="modal-overlay">
      <div className="modal personas-modal">
        <h2>Manage Personas</h2>
        {error && <div className="error-message">{error}</div>}
        <div className="personas-list">
          <h3>Current Personas</h3>
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Title</th>
                <th>Model</th>
              </tr>
            </thead>
            <tbody>
              {personas.map(persona => (
                <tr key={persona.persona_id} onClick={() => handlePersonaSelect(persona)}>
                  <td>{persona.name}</td>
                  <td>{persona.title}</td>
                  <td>{persona.model}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <button onClick={handleNewPersona}>Create New Persona</button>
        </div>
        {selectedPersona && (
          <form onSubmit={handleSubmit}>
            <h3>{selectedPersona.persona_id ? 'Edit Persona' : 'Create New Persona'}</h3>
            <label>
              Name:
              <input type="text" name="name" value={selectedPersona.name} onChange={handleInputChange} required />
            </label>
            <label>
              Title:
              <input type="text" name="title" value={selectedPersona.title} onChange={handleInputChange} required />
            </label>
            <label>
              Description:
              <input type="text" name="description" value={selectedPersona.description} onChange={handleInputChange} required />
            </label>
            <label>
              Model:
              <select name="model" value={selectedPersona.model} onChange={handleInputChange} required>
                <option value="claude-3-5-sonnet-latest">claude-3-5-sonnet-latest</option>
                <option value="gpt-4o">gpt-4o</option>
              </select>
            </label>
            <label>
              Context:
              <textarea name="context" value={selectedPersona.context} onChange={handleInputChange} required />
            </label>
            <div className="modal-actions">
              <button type="submit" disabled={isLoading}>
                {isLoading ? 'Saving...' : 'Save Persona'}
              </button>
              <button type="button" onClick={() => setSelectedPersona(null)}>Cancel</button>
            </div>
          </form>
        )}
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default ManagePersonasModal;